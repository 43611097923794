import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Split Squat 12-12-12/leg`}</p>
    <p>{`Hamstring Curl 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`OH Walking Lunge (45/25)`}</p>
    <p>{`GHD Situps`}</p>
    <p>{`Squat Holding (45/25) Plate`}</p>
    <p>{`Calorie Row `}<em parentName="p">{`(or Ski Erg)`}</em></p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 29th.  For more info
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      